@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');

a {
    text-decoration: none;
}

p {
    margin-bottom: 0px;
}

.top-header {
    background-image: url('../../../../public/images/header_background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh !important;
}

.top-header-new {
    height: 100% !important;
}

.top-header-nav {
    background-image: url('../../../../public/images/header_background.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}

.nav-switch-head {
    display: flex;
    align-items: center;
}

.nav-switch-body {
    display: flex;
    align-items: center;
    gap: 50px;
    padding-left: 0px;
}

.nav-switch-body .form-check-input:focus {
    border-color: none;
    outline: 0;
    box-shadow: unset !important;
}

.logout-link .log-icon {
    position: absolute;
    left: 25px;
    width: 20px;
    height: 26px;
}

.logout-link a {
    color: #033363;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}

.logout-link {
    border-radius: 57px;
    border: 1px solid #1357b5;
    background: #fff;
    padding: 8px 20px 8px 60px;
}

.login-link {
    border-radius: 57px;
    border: 1px solid #1357b5;
    background: #fff;
    padding: 8px 25px;
}

.login-link a {
    margin-right: 0px;
    color: #033363;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}

.nav-link-text {
    color: #fff;
    font-family: Poppins;
    font-size: 23.015px;
    font-weight: 600;
    line-height: normal;
}

.nav-check {
    width: 3em !important;
    height: 1.5em !important;
}

.welcome-head {
    color: #fff;
    font-family: Poppins;
    font-size: 40.294px;
    font-style: normal;
    font-weight: 300;
    line-height: 40.404px;
}

.welcome-head-left {
    color: #fff;
    font-family: Poppins;
    font-size: 68.506px;
    font-style: normal;
    font-weight: 700;
    line-height: 94.196px;
}

.welcome-head-right {
    color: #fff;
    font-family: Poppins;
    font-size: 27.702px;
    font-style: normal;
    font-weight: 300;
    line-height: 38.09px;
}

.custom-drop .ant-select-selector {
    border-radius: 48px !important;
    align-items: center;
    display: flex !important;
    padding: 22px 100px 22px 20px !important;
}

.custom-drop {
    border-radius: 48px !important;
    align-items: center;
    display: flex !important;
}

.drop-new {
    padding-left: 40px;
    padding-left: 40px;
    padding-top: 0.575rem;
    padding-bottom: 0.575rem;
}



.custom-drop-select {
    border-radius: 48px !important;
    background: #fff;
    padding: 10px 15px 10px 15px;
}

.custom-drop-select a {
    color: #033363;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
}

.search-toggle::after {
    /* margin-left: 6.255em !important; */
    margin-left: 8.9em !important;
}



/* table-css */

.main-table {
    background: #fff;
    box-shadow: 0px 11.202186584472656px 56.01093292236328px 0px rgba(50, 50, 50, 0.1);
}

.table>thead {
    vertical-align: middle !important;
}

.main-table thead tr th {
    background: rgba(129, 153, 177, 0.05);
}

/* .main-table thead th {
    padding-top: 15px;
    padding-bottom: 15px;
} */
.table>tbody {
    vertical-align: inherit;
    border-bottom: 1px solid #000;
}

.main-table th {
    color: #323232;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-right: 1px solid #000;
}

.main-table tbody td {
    color: #737373;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
}

.main-table table tr th {
    border: unset !important;
    border-right: 1px solid #000 !important;
}

.search-input-head {
    position: relative;
}

.search-input-icon {
    position: absolute;
    top: 14px;
    left: 14px;
    z-index: 1000;
    width: 18px;
    height: 18px;
}

.switch-toggle {
    position: absolute;
    top: 20px;
    right: 10px;
}

.main-table table tr td {
    border-left: 1px solid #000;
    border-bottom: 1px solid black !important;

}

.cross-icon-head {
    display: none;
}

.pagination-head {
    color: #033363;
    font-size: 16px;

}

/* .header-height {
    height: 100vh;
    overflow: auto;
} */

/* thin-scrollbar */
.thin-scroll {
    max-height: 52vh;
    overflow: auto;
}

.thin-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.thin-scroll::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.thin-scroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.card-form {
    background-image: url('../../../../public/images/header_background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
}

.card-form-body {
    height: 70vh;
}

.card-log {
    background-color: #e6ebef;
    color: #033363;
    border-radius: 48px 48px 0px 0px;
}

.card-log-body {
    padding: 1rem 2rem;
}

.card-log-head {
    border-radius: 48px 48px 48px 48px;
}

.card-log-body {
    color: #999;
}

.log-form-submit {
    display: block;
    margin: 2px auto;
    border-radius: 38px;
    background: #033363;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
    padding: 8px 60px;
}

.log-form-new-submit {
    margin: 2px auto;
    border-radius: 38px;
    background: #033363;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
    padding: 8px 60px;
}

.log-form-head {
    color: #303030;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
}

.get-report-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-navbar-brand {
    height: 80px !important ;
}

@media screen and (max-width: 1650px) {
    .search-toggle::after {
        margin-left: 6.9em !important;
    }

    .welcome-head {
        font-size: 32.294px;
    }

    .welcome-head-left {
        font-size: 60.506px;
        line-height: 75.196px;
    }

    .welcome-head-right {
        font-size: 27.702px;
    }

    .nav-link-text {
        font-weight: 500;
    }

    .new-search-head {
        width: 50% !important;
    }
}

@media screen and (max-width: 1450px) {
    .search-toggle::after {
        margin-left: 2.9em !important;
    }
}

@media screen and (max-width: 1400px) {
    .nav-link-text {
        font-size: 15.015px;
    }

    .logout-link {
        padding: 8px 10px 8px 40px;
    }

    .logout-link .log-icon {
        left: 18px;
        width: 18px;
    }

    .logout-link a {
        font-size: 15.015px;
    }

    .login-link a {
        font-size: 15.015px;
    }
}

@media screen and (max-width: 1200px) {
    .search-toggle::after {
        margin-left: 1.2em !important;
    }

    .custom-drop .ant-select-selector {
        border-radius: 48px !important;
        align-items: center;
        display: flex !important;
        padding: 22px 60px 22px 20px !important;
    }
}

@media screen and (max-width: 991px) {
    .logout-link {
        display: inline-block;
    }

    .login-link {
        display: inline-block;
    }

    .custom-drop-menu {
        width: 50%;
        margin: 0px auto;
        text-align: center;
    }

    .custom-nav-toggler {
        background: white;
    }

    .nav-switch-head {
        justify-content: center;
        padding: 16px 0px;
    }

    .nav-link-text {
        font-size: 20.015px;
    }

    .logout-link a {
        font-size: 20.015px;
    }

    .logout-link {
        padding: 8px 20px 8px 50px;
    }

    .cross-icon-head {
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .custom-navbar-collapse {
        position: absolute;
        visibility: visible;
        opacity: 1;
        margin: 0px !important;
        top: 0px;
        background: #00052bf0;
        width: 100%;
        z-index: 10000;
        left: 0px;
        padding: 25px 50px;
        text-align: center;
    }

    .last-drop {
        width: 50% !important;
    }

    .log-form-new-submit {
        margin: 2px auto;
        border-radius: 38px;
        background: #033363;
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
        padding: 8px 30px;
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .welcome-head {
        font-size: 30.294px;
        line-height: 45.404px;
    }

    .welcome-head-left {
        font-size: 58.506px;
        line-height: 84.196px;
    }

    .welcome-head-right {
        font-size: 17.702px;
        line-height: 28.09px;
    }

    .analyst-dahboard h1 {
        margin-bottom: 0px;
    }

    .get-report-btn {
        display: block;
        text-align: center;
    }
}

@media screen and (max-width: 567px) {
    .search-toggle::after {
        margin-left: 0.2em !important;
    }

    .custom-drop-select a {
        font-size: 16px;
    }

    .welcome-head-left {
        font-size: 48.506px;
        line-height: 74.196px;
    }

    .welcome-head {
        font-size: 25.294px;
        line-height: 36.404px;
    }

    .welcome-head-right {
        font-size: 16.702px;
        line-height: 18.09px;
    }

    .custom-navbar-brand {
        max-width: 80% !important;
    }
}

@media screen and (max-width: 420px) {
    .custom-drop .ant-select-selector {
        padding: 22px 20px 22px 10px !important;
    }

    .switch-toggle {
        position: absolute;
        top: unset;
        bottom: -7px;
    }
}

@media screen and (max-width: 360px) {
    .custom-navbar-brand {
        max-width: 60% !important;
    }

    .navbar-brand-head {
        display: contents;
    }

    .custom-drop-select a {
        font-size: 15px;
    }

    .welcome-head {
        line-height: 26.404px;
    }

    .welcome-head-left {
        line-height: 64.196px;
    }

    .custom-drop .ant-select-selector {
        padding: 16px 5px 16px 5px !important;
    }
}


.cardheight {
    height: 70vh;
}

.bookmark-icon {
    bottom: 35px;
    position: fixed;
    right: 10px;
    background: #fff;
    border-radius: 40px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.timestamp-cols {
    width: 50%;
}

.timestamp-head {
    display: flex;
    gap: 25px;
}

@media screen and (max-width: 767px) {
    .timestamp-cols {
        width: 100%;
    }

    .timestamp-head {
        display: unset;
    }
}


