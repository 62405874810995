@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Manrope:wght@300;400;500;600;700;800&family=Poppins:wght@500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    height: 100%;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
}

.login_wrapper {
    display: flex;
    align-items: center;
    height: 92vh;
    justify-content: center;
    /* background-image: url(/images/bg.png); */
    background-repeat: no-repeat;
}

.login_form {
    border-radius: 60px;
    background: #FFF;
    box-shadow: 4px 4px 100px 0px rgba(0, 0, 0, 0.05);
    margin-top: 15px;
    padding:30px 30px 40px 30px;
}

.login_info {
    min-width: 30%;
}

.login_form_info label {
    display: block;
    color: #323232;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.login_form_info input {
    border-radius: 10px;
    background: #F3F3F3;
    display: block;
    min-height: 50px;
    border: none;
    outline: none;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;
}

.send_btn {
    border-radius: 10px;
    background: #1340D0;
    color: #fff;
    display: inline-block;
    width: 100%;
    padding: 20px;
    text-align: center;
    border: none;
    outline: none;
    margin-top: 25px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.text_center {
    text-align: center;
    width: 100%;
    overflow:hidden
}

h1{
    color: #323232;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 133%;
margin-bottom: 10px;
}
.logo_svg{
    width: 100%;
    text-align: center;
    max-width: 300px;
}

.user_img{
    max-width: 320px;
    width: 100%;
    height: 100%;
    padding: 10px 0px;
}




/* modal css */


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.70);
    backdrop-filter: blur(27px);
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 35%;
    transition: opacity ease-in-out;
    opacity: 1;
    padding: 30px 20px ;
    border-radius: 30px;
background: #FFF;
box-shadow: 4px 4px 100px 0px rgba(0, 0, 0, 0.10);
}

.btn-cross{
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background-color: transparent;
    outline: none;
    box-shadow: none;
}
.btn-cross img{
    width: 15px;
}
.modal-title{
    color: #323232;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 133%;
    margin:25px 0
}

.cancel-btn{
    color: #DE4444;
text-align: center;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-decoration-line: underline;
background-color: transparent;
border: none;
text-align: center;
width: 100%;
padding-top: 20px ;
cursor: pointer;
}

/* modal css */




@media screen and (max-width:1445px) {
    h1{
    font-size: 32px;
    }
    .send-btn {
        font-size: 14px;
        padding: 15px;
    }
    .login-form-info label {
        font-size: 15px;
    }
     
}

@media screen and (max-width:1024px) {

.logo-svg{
    max-width: 200px;
}
}

@media screen and (max-width:767px) {
}